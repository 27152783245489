<script setup>
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  bodyClass: {
    type: String,
    default: '',
  },
  headerClass: {
    type: String,
    default: '',
  },
  footerClass: {
    type: String,
    default: '',
  },
  contentClass: {
    type: String,
    default: '',
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: '',
  },
  staticModal: {
    type: Boolean,
    default: false,
  },
  onShow: {
    type: Function,
    default: undefined,
  },
})

const staticModal = toRef(props, 'staticModal')
const isShown = ref(staticModal.value)
const modal = ref(null)

function showModal() {
  isShown.value = true

  if (props.onShow) {
    props.onShow()
  }
}
function hideModal() {
  if (!props.staticModal) {
    isShown.value = false
  }
}

onMounted(() => {
  modal.value.addEventListener('show.bs.modal', showModal)
  modal.value.addEventListener('hide.bs.modal', hideModal)
})

onBeforeUnmount(() => {
  modal.value?.removeEventListener('show.bs.modal', showModal)
  modal.value?.removeEventListener('hide.bs.modal', hideModal)
})
</script>

<template>
  <div :id="id" ref="modal" class="modal" tabindex="-1">
    <div
      :class="[
        'modal-dialog modal-dialog-centered',
        { 'modal-dialog-scrollable': scrollable },
        { [`modal-${size}`]: size },
      ]"
    >
      <div v-if="isShown" :class="['modal-content', contentClass]">
        <div v-if="!hideHeader" :class="['modal-header', headerClass]">
          <slot name="header">
            <h4 class="modal-title">{{ title }}</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </slot>
        </div>

        <div :class="['modal-body', bodyClass]">
          <slot name="body" />
        </div>

        <div v-if="!hideFooter" :class="['modal-footer', footerClass]">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
